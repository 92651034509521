<script>
import ArrowDownIcon from '../../assets/arrow-down.svg?component'
import ParamsList from "./ParamsList.vue";
import ArrowDownWhiteIcon from "../../assets/arrow-down-white.svg?component";
import CollapseCard from "../card/CollapseCard.vue";
export default {
  props: {
    title: {
      type: String,
      default: 'Dostępne testy'
    },
    products: {
      type: Object,
      required: true,
      default: {}
    }
  },
  components: {CollapseCard, ArrowDownWhiteIcon, ParamsList, ArrowDownIcon},
  setup(){
    const searchSettings = window.searchForm.GetSettings();
    return {
      searchSettings
    }
  },
  data(){
    const sortedProducts = Object.values(this.products).sort((a, b) => a.name.localeCompare(b.name)).filter(el => Object.keys(el.parameters).length > 0);
    return {
      isMobile: window.innerWidth < 992,
      sortedProducts,
      activeParams: sortedProducts[0].parameters,
      activeProduct: sortedProducts[0].id
    }
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.isMobile = window.innerWidth < 992;
    })
  },
  methods: {
    updateActiveProduct(parameters, product_id){
      this.activeParams = parameters;
      this.activeProduct = this.activeProduct !== product_id ? product_id : false;
    }
  },
  computed: {
    gridColumnsCount(){
      return Object.values(this.products).length > 4 ? 'repeat(3, 1fr)' : 'repeat(2, 1fr)';
    }
  }
}
</script>

<template>
  <div class="resultGroup">
    <p><strong>{{title !== 'Pojedynczy parametr' ? title : 'Dostępne testy'}}:</strong></p>
    <div class="resultGroup__wrapper">
      <div class="resultGroup__options">
        <div v-for="product in sortedProducts" class="resultGroup__option">
          <button @click="updateActiveProduct(product.parameters, product.id)" :class="{active: product.id === activeProduct}">
            {{ product.name }}
            <ArrowDownIcon class="arrow" v-if="product.id !== activeProduct" />
            <ArrowDownWhiteIcon class="arrow" v-if="product.id === activeProduct" />
          </button>
          <ParamsList v-if="isMobile && product.id === activeProduct" :parameters="activeParams" :product-id="activeProduct" :join-elements-to-string="searchSettings.settings.joinParameters"/>
        </div>
      </div>
      <CollapseCard :is-open="activeProduct !== false" v-if="!isMobile">
        <ParamsList :parameters="activeParams" :product-id="activeProduct" :join-elements-to-string="searchSettings.settings.joinParameters"/>
      </CollapseCard>
    </div>
  </div>
</template>

<style scoped lang="scss">
.resultGroup{
  margin-top: 24px;

  > p {
    color: var(--primary);
  }

  &__options{
    display: grid;
    grid-template-columns: v-bind(gridColumnsCount);
    gap: 10px;

    @media (max-width: 992px){
      grid-template-columns: 1fr;
    }

    button{
      display: flex;
      align-items: center;
      background-color: var(--white);
      padding: 10px 40px 10px 22px;
      color: var(--gray-50);
      font-weight: 500;
      border-radius: 50px;
      width: 100%;
      text-align: left;
      position: relative;
      transition: color .3s ease, background-color .3s ease;

      @media (min-width: 992px){
        height: 100%;
      }

      svg.arrow{
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
        transition: transform .3s ease;
      }

      &.active{
        color: var(--white);
        background-color: var(--primary);

        svg.arrow{
          transform: translateY(-50%) rotate(180deg);
        }
      }
    }
  }
}
</style>