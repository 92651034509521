<script>
import CheckIcon from "../../assets/check.svg";
import ArrowDownIcon from "../../assets/arrow-down.svg?component";
import ArrowDownWhiteIcon from "../../assets/arrow-down-white.svg?component";
import PDFIcon from '../../assets/pdf.svg?component';
import mime from 'mime-types';
import CollapseCard from "../card/CollapseCard.vue";

export default {
  components: {CollapseCard, ArrowDownIcon, ArrowDownWhiteIcon, CheckIcon, PDFIcon},
  props: {
    title: {
      type: String,
      default: 'Dostępne testy'
    },
    products: {
      type: Object,
      required: true
    }
  },
  setup(props){
    const productsGroups = {};
    if(Object.keys(props.products).length > 0){
      Object.values(props.products).forEach(product => {
        if(!(product.name in productsGroups)){
          productsGroups[product.name] = {
            title: product.name,
            values: []
          };
        }
        productsGroups[product.name].values.push(product);
      });
    }
    return {
      productsGroups: Object.values(productsGroups)
    };
  },
  data(){
    return {
      activeProducts: this.productsGroups.length > 0 ? this.productsGroups[0].values : [],
      activeGroup: this.productsGroups.length > 0 ? this.productsGroups[0].title : ""
    }
  },
  methods: {
    updateActiveProducts(products, group_name){
      this.activeGroup = this.activeGroup !== group_name ? group_name : false;
      this.activeProducts = products;
    },
    clickDownload(url, name, mimeType) {
      let link = document.createElement('a');
      link.download = `${name.split(' ').join('-')}.${mime.extension(mimeType)}`;
      link.target = '_blank';
      link.href = url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  },
  computed: {
    gridColumnsCount(){
      return this.productsGroups.length > 4 ? 'repeat(4, 1fr)' : 'repeat(2, 1fr)';
    }
  }
}
</script>

<template>
  <div class="resultDataTable">
    <p><strong>{{title !== 'Pojedynczy parametr' ? title : 'Dostępne testy'}}:</strong></p>
    <div class="resultDataTable__wrapper" v-if="productsGroups">
      <div class="resultDataTable__options">
        <button v-for="group in productsGroups" @click="updateActiveProducts(group.values, group.title)" :class="{active: group.title === activeGroup}">
          {{ group.title }}
          <ArrowDownIcon class="arrow" v-if="group.title !== activeGroup" />
          <ArrowDownWhiteIcon class="arrow" v-if="group.title === activeGroup" />
        </button>
      </div>
      <CollapseCard :is-open="activeGroup !== false">
        <div class="resultDataTable__content">
          <table v-if="activeProducts">
            <tr v-for="product in activeProducts">
              <td>
                <CheckIcon class="check" />
                {{product.paramsLabel}}
              </td>
              <td>{{product.classes}}</td>
              <td>{{product.methods}}</td>
              <td v-if="product.file">
                <span class="download" @click="clickDownload(product.file.url, product.file.fileName, product.file.mimeType)">
                  <span>{{product.file.fileName}}</span><PDFIcon/>
                </span>
              </td>
            </tr>
          </table>
        </div>
      </CollapseCard>
    </div>
  </div>
</template>

<style scoped lang="scss">
.resultDataTable{
  margin-top: 24px;

  &__options{
    display: grid;
    grid-template-columns: v-bind(gridColumnsCount);
    gap: 10px;
    margin-bottom: 20px;

    @media (max-width: 992px){
      grid-template-columns: 1fr;
    }

    button{
      display: flex;
      align-items: center;
      background-color: var(--white);
      padding: 10px 40px 10px 22px;
      color: var(--gray-50);
      font-weight: 500;
      border-radius: 50px;
      width: 100%;
      text-align: left;
      position: relative;
      transition: color .3s ease, background-color .3s ease;

      svg.arrow{
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
        transition: transform .3s ease;
      }

      &.active{
        color: var(--white);
        background-color: var(--primary);

        svg.arrow{
          transform: translateY(-50%) rotate(180deg);
        }
      }
    }
  }

  &__content{
    background-color: var(--white);
    padding: 18px 32px;
    border-radius: 20px;

    @media (max-width: 992px){
      padding: 12px 16px;
      overflow-x: auto;
      max-width: 100%;
    }

    table{
      width: 100%;

      @media (max-width: 992px){
        width: max-content;
      }

      .download{
        display: flex;
        gap: 6px;
        align-items: center;
        justify-content: right;
        text-align: right;
        text-decoration: underline;
        cursor: pointer;
        transition: color .3s ease;

        &:hover{
          color: var(--primary);
        }

        svg{
          width: 28px;
          height: auto;
        }
      }
    }
  }

  table{
    border-spacing: 0 8px;

    td{
      min-width: 100px;

      &:first-child{
        display: flex;
        align-items: center;
        gap: 14px;
      }

      .check{
        min-width: 16px;
      }

      &:not(:last-child){
        padding-right: 45px;
      }
    }
  }
}
</style>