<script>
import { ref } from "vue";
import { VueRecaptcha } from 'vue-recaptcha';
import SearchPrimaryIcon from '../assets/search-primary.svg?component';
import FilterForm from "../components/FilterForm.vue";
import ArrowDown from '../assets/arrow-down.svg?component';
import ArrowDownPrimary from '../assets/arrow-down-primary.svg?component';
import RemoveIcon from "../assets/remove.svg?component";

export default {
    props: {
      location: {
        type: Object,
        default: {
          loc: '',
          lng: '',
          lat: ''
        }
      },
      searchData: {
        type: Object,
        default: {}
      },
      isBlock: {
        type: Boolean,
        default: false
      }
    },
    components: {RemoveIcon, FilterForm, VueRecaptcha, SearchPrimaryIcon, ArrowDown, ArrowDownPrimary},
    setup(){
      const searchSettings = window.searchForm.GetSettings();
      return {
        paramsData: window.searchForm.GetParamsData(),
        queryParamsList: window.searchForm.GetQueryParams(),
        searchSettings,
        searchResultsTitle: window.illness_search.search_settings.above_results_description,
        recaptchaKey: window.illness_search.search_recaptcha,
        searchInputForm: ref(null)
      };
    },
    data(){
      const enableCaptcha = this.$cookies.isKey('form-submit-latency') && (parseInt(this.$cookies.get('form-submit-latency')) % 3 === 0);
      return {
        showFullList: false,
        showFilters: false,
        searchLocation: {
          loc: this.location.loc,
          lng: this.location.lng,
          lat: this.location.lat
        },
        suggestions: [],
        inputError: false,
        timeout: null,
        timeoutController: null,
        captcha: '',
        isMobile: window.innerWidth < 768,
        enableCaptcha,
      }
    },
  mounted() {
    document.addEventListener('click', () => {
      this.suggestions = [];
    });
    window.addEventListener('resize', () => {
      this.isMobile = window.innerWidth < 768;
    });
  },
  methods: {
    handleFullList(){
      this.showFullList = !this.showFullList;
    },
    handleFilters(){
      this.showFilters = !this.showFilters;
    },
    handleUpdateSearchParams(values){
      this.queryParamsList = values;
      window.searchForm.SetQueryParams(values);
      this.paramsData = window.searchForm.GetParamsData()
      this.showFullList = false;
    },
    handleSuggestion(point){
      this.searchLocation = {
        loc: point.loc,
        lng: point.lng,
        lat: point.lat
      }
      this.suggestions = [];
      this.inputError = false;
    },
    preventClosing(e){
      e.preventDefault();
      e.stopPropagation();
    },
    async getHintsLocations(){
      const response = await fetch(`https://nominatim.openstreetmap.org/search?${new URLSearchParams({
        format: 'json',
        'accept-language': 'pl',
        countrycodes: 'pl',
        q: this.searchLocation.loc
      }).toString()}`);
      if (!response.ok) return;
      const json = await response.json();
      return Array.isArray(json) && json.length > 0 ? json : [];
    },
    async handleSubmit(e) {
      if (e) e.preventDefault();

      this.inputError = false;
      if(this.searchLocation.loc.length === 0){
        this.inputError = true;

        window.scrollTo({
          top: this.searchInputForm.getBoundingClientRect().top - (window.innerWidth >= 992 ? 220 : 120),
          behavior: 'smooth'
        });

        return;
      }

      if(this.captcha.length === 0 && this.enableCaptcha) return alert("Wypełnij test reCaptcha");

      if(!this.$cookies.get('form-submit-latency')) this.$cookies.set('form-submit-latency', 1);
      else this.$cookies.set('form-submit-latency', parseInt(this.$cookies.get('form-submit-latency')) + 1);

      try {
        const json = await this.getHintsLocations();
        if(json.length === 0) return;

        this.searchLocation.lng = json[0].lon;
        this.searchLocation.lat = json[0].lat;
        this.searchLocation.loc = json[0].display_name;
      } catch (err){
        console.error(err);
      } finally {
        const queryString = new URLSearchParams({
          parameter: JSON.stringify(this.queryParamsList),
          lng: this.searchLocation.lng,
          lat: this.searchLocation.lat,
          loc: this.searchLocation.loc
        });
        window.location = `${window.illness_search.search_page_url}/?${queryString.toString()}`;
      }
    },
    handleInput(e) {
      this.searchLocation.loc = e.target.value;
      this.searchLocation.lng = '';
      this.searchLocation.lat = '';
      this.inputError = false;
      if(this.searchLocation.loc.length === 0){
        this.inputError = true;
        this.suggestions = [];
        clearTimeout(this.timeout);
        return;
      }

      clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        try {
          const json = await this.getHintsLocations();
          if(this.timeout !== this.timeoutController) return;
          this.suggestions = [];
          if(json.length === 0) return;
          json.forEach(point => {
            this.suggestions.push({
              loc: point.display_name,
              lat: point.lat,
              lng: point.lon
            });
          });
          this.suggestions.sort((a,b) => a.loc.localeCompare(b.loc));
        } catch (err){
          console.error(err);
        }
      }, 200);
      this.timeoutController = this.timeout;
    },
    handleSuccess(successCode){
      this.captcha = successCode;
    },
    handleError(){
      this.captcha = '';
    },
    handleRemove(param){
      if(param.id in this.queryParamsList){
        delete this.queryParamsList[param.id];
      } else if(param.parent) {
        this.queryParamsList[param.parent].splice(this.queryParamsList[param.parent].indexOf(param.id), 1);

        if(this.queryParamsList[param.parent].length === 0) delete this.queryParamsList[param.parent];
      }

      this.handleUpdateSearchParams(this.queryParamsList);
    }
  }
};
</script>

<template>
  <div class="resultForm" :class="{'resultForm--block': isBlock}">
    <h1 v-if="searchResultsTitle && !isBlock" class="resultForm__title">{{searchResultsTitle}}</h1>
    <form @submit="handleSubmit" ref="searchInputForm">
      <h1 v-if="!isBlock">Wyniki wyszukiwania:</h1>
      <div class="resultForm__input">
        <input type="text" :value="searchLocation.loc" @input="handleInput" placeholder="Wpisz miasto, ulicę" :class="{isError: inputError}">
        <SearchPrimaryIcon @click="handleSubmit" v-if="!isMobile && !isBlock"/>
        <span class="search" @click="handleSubmit" v-if="isBlock">Szukaj</span>
        <ul class="resultForm__suggestions" v-if="suggestions.length > 0" @click="preventClosing">
          <li v-for="point in suggestions" @click="handleSuggestion(point)">
            {{point.loc}}
          </li>
        </ul>
      </div>
      <button class="btn btn--primaryFill" v-if="isMobile" @click="handleSubmit">Szukaj</button>
    </form>
    <div class="resultForm__captcha" v-if="enableCaptcha">
      <VueRecaptcha
          :sitekey="recaptchaKey"
          :load-recaptcha-script="true"
          @verify="handleSuccess"
          @error="handleError"
      ></VueRecaptcha>
    </div>
    <div v-if="searchSettings.settings.advanced_search">
      <FilterForm v-if="showFilters" v-on:close-filters="handleFilters" v-on:update-search-params="handleUpdateSearchParams" v-on:submit="handleSubmit" :is-block="isBlock"/>
      <div class="resultForm__list" v-if="!isBlock && (!showFilters && paramsData.length > 0)">
        <div class="resultForm__list__nav">
          <p>Kryteria zaawansowane:</p>
          <span @click="handleFilters">Zmień kryteria</span>
        </div>
        <ul>
          <li v-for="paramData in paramsData.slice(0, 17)" :class="{active: paramData.active}">{{paramData.name}}<RemoveIcon class="remove-element" @click="handleRemove(paramData)"/></li>
          <li v-if="showFullList" v-for="paramData in paramsData.slice(17)" :class="{active: paramData.active}">{{paramData.name}}<RemoveIcon class="remove-element" @click="handleRemove(paramData)"/></li>
          <li v-if="paramsData.length > 17" class="show_more" @click="handleFullList">{{!showFullList ? 'Pokaż więcej' : 'Pokaż mniej'}}</li>
        </ul>
      </div>
      <div class="resultForm__filters" v-if="!isBlock && (!showFilters && paramsData.length === 0)">
        <span @click="handleFilters">Kryteria zaawansowane <ArrowDown/></span>
      </div>
      <div class="resultForm__filters" v-if="isBlock && !showFilters">
        <span @click="handleFilters">Kryteria zaawansowane <ArrowDown/></span>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.resultForm{
  margin-bottom: 16px;
  position: relative;

  @media (max-width: 992px){
    padding: 0 16px 16px 16px;
  }

  form{
    display: flex;
    align-items: center;
    gap: 16px;
    max-width: var(--width-main);
    width: 100%;
    margin: 0 auto 20px auto;

    @media (max-width: 992px){
      flex-direction: column;
    }

    h1{
      min-width: max-content;
      margin: 0;
    }

    button.btn{
      width: 100%;
    }
  }

  &__title{
    text-align: center;
    max-width: var(--width-main);
    width: 100%;
    margin: 0 auto 32px auto;
    color: var(--primary);
  }

  &__captcha{
    max-width: var(--width-main);
    width: 100%;
    margin: 0 auto 20px auto;
    display: flex;
    justify-content: right;
  }

  &__suggestions {
    position: absolute;
    top: calc(100% + 6px);
    left: 40px;
    padding: 20px;
    border-radius: 20px;
    background-color: var(--white);
    box-shadow: 1px 1px 3px 0 var(--gray-50);
    max-width: calc(100% - 80px);
    max-height: 200px;
    overflow-y: auto;
    z-index: 99;

    display: flex;
    flex-direction: column;
    gap: 16px;
    list-style: none;
    margin: 0;

    @media (max-width: 992px){
      max-width: calc(100% - 16px);
      left: 8px;
      max-height: 120px;
    }

    li{
      cursor: pointer;
      font-size: 14px;
      transition: color .3s ease;

      &:hover{
        color: var(--primary);
      }
    }
  }

  &__input{
    position: relative;
    width: 100%;

    input{
      width: 100%;
      padding: 20px 60px 20px 40px;
      border-radius: 50px;
      border: none;
      font-weight: 500;
      background-color: var(--gray-10);
      color: var(--gray-50);
      outline: none;

      @media (max-width: 992px){
        padding: 10px 30px 10px 20px;
      }

      &.isError{
        border: 2px solid #f00;
        color: #f00;
      }
    }

    svg,
    span.search{
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
      cursor: pointer;

      @media (max-width: 992px){
        right: 10px;
      }
    }

    span.search{
      padding: 6px 12px;
      background-color: var(--primary);
      border-radius: 25px;
      color: var(--white);
    }
  }

  &__list{
    border: 1px solid #d9d9d9;
    border-radius: 20px;
    padding: 20px 36px;
    max-width: var(--width-main);
    width: 100%;
    margin: 0 auto;

    @media (max-width: 992px){
      padding: 10px 16px;
    }

    &__nav{
      position: relative;

      span{
        position: absolute;
        top: 50%;
        right: 30px;
        transform: translateY(-50%);
        text-decoration: underline;
        cursor: pointer;

        @media (max-width: 992px){
          position: static;
        }
      }

      p{
        @media (max-width: 992px){
          margin-bottom: 8px;
        }
      }
    }

    ul{
      list-style: none;
      margin: 20px 0 0 0;
      padding: 0 0 10px 0;
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      max-height: 300px;
      overflow-y: auto;

      @media (max-width: 992px){
        max-height: 150px;
      }

      li{
        display: flex;
        align-items: center;
        gap: 4px;
        padding: 8px 20px;
        background-color: var(--gray-10);
        border-radius: 50px;

        &.active{
          background-color: rgba(var(--primary-rgb), 0.1);
        }

        &.show_more{
          background-color: transparent;
          border-radius: 0;
          text-decoration: underline;
          cursor: pointer;
        }

        .remove-element{
          width: 16px;
          height: auto;
          cursor: pointer;
        }
      }
    }
  }

  &__filters{
    display: flex;
    justify-content: right;
    max-width: var(--width-main);
    width: 100%;
    margin: 20px auto;

    > span{
      display: flex;
      align-items: center;
      gap: 8px;
      color: var(--gray-50);
      cursor: pointer;
    }
  }

  &--block{

    form,
    .resultForm__captcha{
      max-width: 690px;
    }

    .resultForm__input{
      input{
        background-color: var(--white);
      }
    }
    .resultForm__filters {
      max-width: 690px;
      margin: 0 auto;

      span {
        font-weight: 500;
      }
    }
  }
}
</style>