<script>
export default {
  props: {
    items: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const points = Object.values(props.items).map((item) => ({
      lat: item.point.lat,
      lng: item.point.lng,
      primaryColor: window.illness_search.primary_color,
      nazwa: item.point.nazwa,
      ulica: item.point.ulica,
      kod_pocztowy: item.point.kod_pocztowy,
      miasto: item.point.miasto,
      telefon: item.point.telefon,

      distance: item.distance,
      hierarchy_point: item.how_many_points,
    }));

    points
        .sort((a, b) => a.distance - b.distance)
        .sort((a, b) => a.hierarchy_point.toString().localeCompare(b.hierarchy_point.toString()));

    let zoom = points.length > 0 && points[points.length - 1].distance * 1000 > 2 ? 12 - ((points[points.length - 1].distance * 1000).toFixed(0)) : 13;
    if(zoom < 6) zoom = 6;

    return { zoom, points };
  },
  data() {
    const urlParams = new URLSearchParams(window.location.search);
    return {
      center: {
        lat: window.illness_search.lat,
        lng: window.illness_search.lng,
      },
      primaryColor: window.illness_search.primary_color,
      infoWindowOpened: false,
      infoWindowPosition: { lat: 0, lng: 0 },
      infoWindowContent: {
        nazwa: "",
        ulica: "",
        kod_pocztowy: "",
        miasto: "",
        telefon: "",
      },
      urlParams,
    };
  },
  methods: {
    openInfoWindow(point) {
      this.infoWindowOpened = true;
      this.infoWindowPosition = { lat: point.lat, lng: point.lng };
      this.infoWindowContent = {
        nazwa: point.nazwa,
        ulica: point.ulica,
        kod_pocztowy: point.kod_pocztowy,
        miasto: point.miasto,
        telefon: point.telefon,
      };
    },
  },
};
</script>

<template>
  <GMapMap :center="center" :zoom="zoom" map-type-id="terrain" class="google-map">
    <GMapMarker
      :icon="{
        path: 'M32.75,11.95A15.11,15.11,0,0,0,32,10,16.11,16.11,0,0,0,16.78,0,16.16,16.16,0,0,0,.47,14.92v2a9.86,9.86,0,0,0,.07,1.24C1.14,23,4.91,28.06,7.73,32.84,10.75,38,13.9,43,17,48c1.92-3.29,3.83-6.61,5.71-9.81,0.51-.94,1.11-1.87,1.62-2.77,0.34-.6,1-1.19,1.29-1.75,3-5.55,7.91-11.13,7.91-16.67V14.74a17,17,0,0,0-.78-2.81h0v0Z',
        fillColor: '#df0c0c',
        fillOpacity: 1,
        strokeWeight: 0,
        anchor: { x: 17, y: 49 },
        labelOrigin: { x: 17, y: 17 },
      }"
      :label="{
        color: '#FFFFFF',
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: 'bold',
        text: '&#x2022;',
      }"
      :position="{
        lat: parseFloat(urlParams.get('lat')),
        lng: parseFloat(urlParams.get('lng')),
      }"
      :animation="4"
      :clickable="true"
      :draggable="false"
    />
    <GMapMarker
      v-for="(point, index) in points"
      :key="index"
      :icon="{
        path: 'M32.75,11.95A15.11,15.11,0,0,0,32,10,16.11,16.11,0,0,0,16.78,0,16.16,16.16,0,0,0,.47,14.92v2a9.86,9.86,0,0,0,.07,1.24C1.14,23,4.91,28.06,7.73,32.84,10.75,38,13.9,43,17,48c1.92-3.29,3.83-6.61,5.71-9.81,0.51-.94,1.11-1.87,1.62-2.77,0.34-.6,1-1.19,1.29-1.75,3-5.55,7.91-11.13,7.91-16.67V14.74a17,17,0,0,0-.78-2.81h0v0Z',
        fillColor: primaryColor,
        fillOpacity: 1,
        strokeWeight: 0,
        anchor: { x: 17, y: 49 },
        labelOrigin: { x: 17, y: 17 },
      }"
      :label="{
        color: '#FFFFFF',
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: 'bold',
        text: (index + 1).toString(),
      }"
      :position="{ lat: point.lat, lng: point.lng }"
      :animation="4"
      :clickable="true"
      :draggable="false"
      @click="openInfoWindow(point)"
    />
    <GMapInfoWindow
      :options="{ pixelOffset: { width: 0, height: -30 } }"
      :position="infoWindowPosition"
      :opened="infoWindowOpened"
      @closeclick="infoWindowOpened = false"
    >
      <div class="google-map__info-window">
        <p>{{ infoWindowContent.nazwa }}</p>
        <p>
          {{ infoWindowContent.ulica }}, {{ infoWindowContent.kod_pocztowy }}
          {{ infoWindowContent.miasto }}
        </p>
        <p>Tel: {{ infoWindowContent.telefon }}</p>
      </div>
    </GMapInfoWindow>
  </GMapMap>
</template>

<style scoped lang="scss">
.google-map {
  width: 100%;
  height: 350px;
  margin-bottom: 40px;

  @media (max-width: 992px) {
    padding: 0 20px;
    height: 200px;
  }

  &__info-window {
    p {
      font-size: 14px;
      line-height: 1.2;
      margin-bottom: 0;
    }
  }
}
</style>
