<script>
export default {
  name: "CollapseCard",
  props: {
    isOpen: {
      type: Boolean,
      required: true,
      default: false
    },
    time: {
      type: Number,
      default: 500
    }
  },
  data() {
    return {
      active: this.isOpen,
      status: 'collapsed', // extending, extended, collapsed, collapsing
      changing: false
    }
  },
  watch: {
    isOpen(val) {
      this.status = val ? 'extending' : 'collapsing';
      this.active = val;

      this.toggle();
    }
  },
  mounted() {
    this.status = this.isOpen ? 'extended' : 'collapsed';
  },
  methods: {
    toggle() {
      this.$el.style.display = "block";
      let contentHeight = this.$el.scrollHeight;

      setTimeout(() => {
        if (this.active) this.extend(contentHeight);
        else this.collapse(contentHeight);
      }, 10);
    },
    extend(height = 1) {
      setTimeout(() => {
        this.status = 'extended';
        this.$el.style.height = 'auto';
      }, this.time - 10);

      this.$el.style.maxHeight = `${height}px`;
    },
    collapse() {
      setTimeout(() => {
        this.status = 'collapsed';
        this.$el.style.height = 'auto';
        this.$el.style.display = "none";
      }, this.time - 10);

      this.$el.style.maxHeight = `0px`;
    }
  },
  computed: {
    timing() {
      return `${this.time - 10}ms`;
    }
  }
}
</script>

<template>
  <div class="collapse-card" :data-collapse="status">
    <slot />
  </div>
</template>

<style scoped lang="scss">
.collapse-card {
  &[data-collapse="collapsing"],
  &[data-collapse="extending"]{
    overflow: hidden;
    transition: max-height v-bind(timing) ease-in-out;
  }

  &[data-collapse="collapsed"]{
    display: none;
  }
}
</style>